// Bootstrap JS
const bootstrap = require('bootstrap');
import ScrollToTop from '../../_includes/partials/components/scrollToTop/scrollToTop';
import Contact from '../../_includes/partials/components/contact/contact';

document.addEventListener("DOMContentLoaded", function(){

    // Disable right click
    // document.addEventListener('contextmenu', event => event.preventDefault());

    // Typewriter effect on main title
    (function () {
        var titleNode = document.querySelector('#home-title-container .title-text');
        var caretNode = document.querySelector('#home-title-container .title-caret');
        var subtitleNode = document.querySelector('#home-subtitle-container');

        // Retrieve translation
        var titleText = titleNode.dataset.title;

        setTimeout(function () {
        var i = 0;
        var handle = setInterval(function () {
            if (i >= titleText.length) {
            clearInterval(handle);
            caretNode.style.display = 'none';
            subtitleNode.style.opacity = '1';
            return;
            }
            titleNode.textContent += titleText.charAt(i);
            i++;
        }, 120);
        }, 1000);
    })();

    // Hide on scroll
    let el_autohide = document.querySelector('.autohide');    
    let el_navbar = document.querySelector('.navbar');
    let el_header_menu = document.querySelectorAll('#header-menu');

    if(el_autohide){
        let last_scroll_top = 0;
        window.addEventListener('scroll', function() {
            let scroll_top = window.scrollY;
            if(scroll_top < last_scroll_top) {
                el_autohide.classList.remove('scrolled-down');
                el_autohide.classList.add('scrolled-up');                
            }
            else {
                el_autohide.classList.remove('scrolled-up');
                el_autohide.classList.add('scrolled-down');                
            }
            last_scroll_top = scroll_top;

            // Manage Navbar Background
            if (el_navbar) {
                const LIMIT_NAVBAR_BG = 100;
                if (window.scrollY > LIMIT_NAVBAR_BG) {
                    el_navbar.classList.add('bg-white');
                    el_navbar.classList.remove('bg-transparent');
                    for (let node of el_header_menu) {
                        node.classList.add('text-primary');     
                        node.classList.remove('text-white');
                    }
                } else {
                    el_navbar.classList.add('bg-transparent');
                    el_navbar.classList.remove('bg-white');
                    for (let node of el_header_menu) {
                        node.classList.add('text-white');     
                        node.classList.remove('text-primary');
                    }
                }
            }
        }); 
        // window.addEventListener
    }

    // Language switcher button color
    let el_switcher = document.querySelector('.switcher');
    if (el_switcher) {
        el_switcher.classList.add('btn-light');
    }

    if (!window.gdprWebsiteDisplayed) {
        const gdprModalElt = document.getElementById("gdpr-modal");
        const gdprModal = new bootstrap.Modal(gdprModalElt);
        gdprModal.show();
    }

    window.setGdprConsent = (acceptAll) => {
        if (!window.localStorage) {
            return;
        }
        const cookiesStatsElt = document.querySelector("#gdpr-cookies-stats");
        if (!cookiesStatsElt) {
            return;
        }
        const isAccepted = acceptAll || cookiesStatsElt.checked;
        window.localStorage.setItem("aspotWebsiteGdprConsent", isAccepted)
        window.location.reload();
    };
});